import React, { useCallback, useEffect, useRef, useState } from 'react';
import Veto from "./Veto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import Config from "../../../Config";
import LoadingSpinner from "../../navigation/LoadingSpinner";
import { useParams } from 'react-router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import logo from "../../../resource/logos/joint logo KC TEAM SERIES.png";
import logo_artisan from "../../../resource/logos/logo_artisan.png"
import logo_aster from "../../../resource/logos/logo_aster.png"
import logo_copysat from "../../../resource/logos/logo_copysat.png"
import logo_estoty from "../../../resource/logos/logo_estoty.png"
import logo_PCT from "../../../resource/logos/logo_PCT.png"
import logo_highvideo from "../../../resource/logos/logo_highvideo.png"
import logo_lock0 from "../../../resource/logos/logo_lock0.png"
import logo_datavirtual from "../../../resource/logos/logo_datavirtual.net.png"


import logos_agrupados from "../../../resource/logos/tira sponsors KC TEAM SERIES.png"
import backgoundvideo from "../../../resource/background-video.webm"
import fondoTwitch from "../../../resource/fondo_timer_witch.png";
import player_maxter from "../../../resource/players/maxter.png"
import player_sombra from "../../../resource/players/Sombr4.png"
import Timer from '../Timer';
import TimerContext from '../MyContext';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useLocation } from 'react-router-dom';
import fondo from '../../../resource/fondo.png'


const Stream = () => {
    const refreshInterval = useRef<NodeJS.Timer | null>(null);
    const refreshTimer = useRef<NodeJS.Timer | null>(null);

    const { hash, player, secret } = useParams<{ hash: string; player?: string; secret?: string; }>();

    const [ data, setData ] = useState<any>({});
    const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
    const [ currentPlayer, setCurrentPlayer ] = useState<string>('');
    const [ currentDate, setCurrentDate ] = useState<Date>();
    const [ minutes, setMinutes ] = useState<any>(0);
    const [ seconds, setSeconds ] = useState<any>(0);
    const [ secondsConfig, setSecondsConfig ] = useState<any>(0);
    const [ minutesConfig, setMinutesConfig ] = useState<any>(1);
    const [ connection, setConnection ] = useState<any>(null);
    const location = useLocation();
    const [next, setNext ] = useState<string>('');

    const playNotificationSound = () => (document.getElementById('NotificationSound') as HTMLAudioElement)?.play();
 
    document.onvisibilitychange = () => {
        if (document.visibilityState === "visible") {
            if (connection) {
                var path = location.pathname.split("/");
                var hash = path[2];
                var secret = path[3];
                var playerId = path[4];
                    
                const json =  {Hash: hash, Secret: secret, PlayerId: playerId, Value: 0}
                connection.invoke("Ready", JSON.stringify(json)  ).then(() => {
                    console.log("Ready!")
                });
            }
        }
    }

    const setTimeLeft = useCallback ((segundosranscurridos, minutosTranscurridos, s , m) => {
    
        if(minutosTranscurridos === m && segundosranscurridos === s){
            setSeconds(0);
            setMinutes(0);
            return;
        }

        if(minutosTranscurridos === m  && segundosranscurridos >= s){
            setSeconds(0);
            setMinutes(0);
            return;
        }

        if(minutosTranscurridos >  m){
            setSeconds(0);
            setMinutes(0);
            return;
        }
       
        var secondsRestantes = 0;
        var minutesRestantes = 0;
       
        if(segundosranscurridos <= s ){
            secondsRestantes =  Math.abs(s-segundosranscurridos);
            minutesRestantes =   Math.abs(minutosTranscurridos-m) ;
        }
        else{
            secondsRestantes = 60 - Math.abs(s-segundosranscurridos);
            minutesRestantes =   Math.abs(minutosTranscurridos-m) -1;
        }


           
        setSeconds(secondsRestantes);
        setMinutes(minutesRestantes);
        
    }, [setMinutes, setSeconds])

    const timeLeft = useCallback ((segundosranscurridos, minutosTranscurridos, s , m, secondsConf, minutesConf) => {
    
        var secondsRestantes = 0;
        var minutesRestantes = 0;
       
        if(segundosranscurridos <= s ){
            secondsRestantes =  Math.abs(s-segundosranscurridos);
            minutesRestantes =   Math.abs(minutosTranscurridos-m) ;
        }
        else{
            secondsRestantes = 60 - Math.abs(s-segundosranscurridos);
            minutesRestantes =   Math.abs(minutosTranscurridos-m) -1;
        }

        setTimeLeft(secondsRestantes, minutesRestantes, secondsConf, minutesConf);

    }, [ setTimeLeft])

    useEffect(() => {

       load();
    }, [connection, location, timeLeft]);

function load(){
    var lastStepDate = "";
    var minConf = 0;
    var secConfig = 0;
    if (connection) {
        connection.start()
            .then(() => {
                console.log('Connected!');
  
                var path = location.pathname.split("/");
                var hash = path[2];
              
                const json =  {Hash: hash, Value: 0}
                connection.invoke("Ready", JSON.stringify(json)  ).then(() => {
                    console.log("Ready!")
                });

                connection.on(
                    "MatchUpdate",
                    (response: any) => {
                        if(response.ok){
                             console.log(response.data);    
                          
                                if(response.data.nextStepIndex < response.data.steps.length ){
                                    var next = response.data.steps[response.data.nextStepIndex].player;
                                    setNext(next);
                                    var lsd = document.getElementById("nextStepIndex") as HTMLInputElement;
                                    if(lsd !== null && response.data.nextStepIndex!== parseInt( lsd.value) )
                                        playNotificationSound();
                                 } 
                        
                             if(!response.data.isLastStep){
                                var m = Math.floor(response.data.matchSeconds / 60);
                                var s = response.data.matchSeconds % 60;
                                setMinutesConfig(m);
                                setSecondsConfig(s);
                                minConf = m;
                                secConfig = s;

                                setMinutes(m);
                                setSeconds(s);
                             }
                          
                             var readyPlayerOne = response.data.playerOne.isReady;
                             var readyPlayerTwo = response.data.playerTwo.isReady;
                         
                         
                             setData({ ...response.data });
                             setIsLoaded(true);

                             if(readyPlayerOne && readyPlayerTwo && !response.data.isLastStep){
                            
                                lastStepDate = response.data.lastStepDate;
                                connection.invoke("TimeCheck").then(() => {
                                    console.log("TimeCheck!"); 
                                
                                });

                             }
                        }
                        else{
                            console.log(response.error);
                        }
                    }
                );

                connection.on(
                    "TimeCheck", 
                    (response: any) => {
                        var currentTime = new Date(response.timestamp);
                 
                        var lastStepDatee = new Date(lastStepDate);

                        var s1 = currentTime.getSeconds();
                        var s2 = lastStepDatee.getSeconds();

                        var m1 = currentTime.getMinutes();
                        var m2 = lastStepDatee.getMinutes();

                       timeLeft(s2, m2, s1, m1, secConfig, minConf);
                    }
                );

                connection.on(
                    "Error", 
                    (response: any) => {
                        debugger;
                    }
                );
            })
            .catch((e: any) => console.log('Connection failed: ', e));
    }
}

    function createHubConnection() {
        const options = {
            withCredentials: false,
        };
        const con = new HubConnectionBuilder()
          .withUrl(`${Config.apiUrl}/matchHub`, options)
          .withAutomaticReconnect()
          .build();
        setConnection(con);
      }
    
      useEffect(() => {
        createHubConnection();
      }, []);


      useEffect(() => {
        if(secondsConfig === 0 && minutesConfig === 0 ){

           //por defecto
            if (refreshInterval.current) {
                clearInterval(refreshInterval.current);
                refreshInterval.current = null;
            }
        }
         else{
            //con timer
            const countdownPick = setInterval(() => {
                    if(data.nextStepIndex >= data.steps.length){
                        
                        clearInterval(countdownPick);

                        setMinutes(0);
                        setSeconds(0);


                        return;
                    }

                    if(data.playerOne.isReady &&  data.playerTwo.isReady ){
                    
                        if (seconds <= 0) {
                            if (minutes <= 0) {
                                console.log("timer is up!");
                                clearInterval(countdownPick);  
                            } else {
                                var x = minutes - 1;
                                setMinutes(x);
                                setSeconds(59);
                            }
                        }
                        else {       
                                let x = (seconds - 1);
                                let y = minutes;
                                setMinutes(y);
                                setSeconds(x);
                        }
                    }

                }, 1000);

            if (refreshTimer.current) {
                clearInterval(refreshTimer.current);
                        refreshTimer.current = null;
                        refreshTimer.current = countdownPick;
            }
        
            else
                refreshTimer.current = countdownPick;

            return () => clearInterval(countdownPick);
        }
    }, [ hash, data, seconds, minutes, setMinutes, setSeconds, minutesConfig, secondsConfig]);

    const renderIndexTitle = (index: number) => {
  
        if (index === 0) return 'One';
        else if (index === 1) return 'Two';
        else if (index === 2) return 'Three';
        else if (index === 3) return 'Four';
        else if (index === 4) return 'Five';
        else if (index === 5) return 'Six';
        else if (index === 6) return 'Seven';
    }

    const GetIndexMapPicked = (index: number) => {

        var xx = data.steps[index];
        if(xx == null) return -1;
        if(xx.value == null) return -1;

        if(xx.type !== "map_pick") return -1;

        let result = 0;
        data.maps.picked.forEach((element: number, i: number) => {
            if(element === xx.value){
                result = i;
            };
        });

         return result;
    }


    const renderSplit = (index: number) => {
        console.log(index);
            return (
                <div className={"no-padding overflow-hidden"}>
                   
                    <div className={"grid grid-cols-1 gap-1"}>

                        {data.steps.map((value:any, key:number) => (key > data[`matchSplitMap${renderIndexTitle(index)}`] && key <= (data.nextStepIndex) && key <= data[`matchSplitMap${renderIndexTitle(index + 1)}`]) && (
                    
                           <div style={{maxHeight: 70}} className={`w-full ${data.steps[key].value}`} key={key}>
                        
                                <Veto secret={matchSecret} 
                                hash={String(hash)} 
                                currentName={currentPlayer} 
                                name={data.steps[key].player === 'playerOne' ? data.playerOne.name : data.playerTwo.name} 
                                type={data.steps[key].type} 
                                value={data.steps[key].value} 
                                maps={data.maps.items} 
                                mapsAvailable={data.maps.available} 
                                champions={data.champions.items} 
                                championsAvailable={data.champions.available} 
                                next={key >= data.nextStepIndex} 
                                waitingon={next === 'playerOne' ? data.playerOne.name : data.playerTwo.name}/>
                            </div>
                        ))}
                    </div>
                </div>
            )
    }

    const renderNone = () => {
        return (
            <div className={"no-padding overflow-hidden"}>
               
                <div className={"grid grid-cols-1 gap-1"}>
                   
                </div>
            </div>
        )

}


const renderImages =  () => {
    try{
        
  
        var player1 = data.playerOne.name;
        var player2 = data.playerTwo.name;
        var x = require(`../../../resource/players/${player1}.png`);
        var y = require(`../../../resource/players/${player2}.png`);
        if(x!== undefined && y !== undefined){
     
        return (
            <div>
                <div id="fondoimagen1"> 
                    <img alt='fonfdo' style={{height: '100vh'}} src={require(`../../../resource/players/${player1}.png` )}></img>
                </div>
                <div id="fondoimagen2"> 
                    <img alt='fondo' style={{height: '100vh'}} src={require(`../../../resource/players/${player2}.png`)}></img>
                </div>
        </div>
        )
    }
}
catch(e){

}

}
    
    let matchSecret = "";
    if (secret)
        matchSecret = secret;

    return isLoaded ? (
        
        <div>
         { renderImages()}
        <>
        <video autoPlay loop muted id="video">
            <source src={backgoundvideo} type='video/webm'></source>
        </video>

     
        {/* <img id= "video" style={{height: '100vh'}} src={fondo}></img> */}
          

        <div className='w-10/12 mx-auto mb-20' style={{backgroundImage: fondo}}>
        <input type='hidden' id="nextStepIndex" value={data.nextStepIndex} ></input>

        <div className="grid grid-cols-5 md:grid-cols-5 mb-6 pt-12" style={{ position: "relative" }}>
    <div className="col-span-4">
        <div className="flex justify-start items-center space-x-2 relative">
            <img className="logo2" src={logos_agrupados} alt="Logos Agrupados" />
            <img src={logo} className="keencon-img absolute right-0" alt="KeenCon" />
            </div>
            <div className="linea"></div>
        </div>
    </div>

              
                <div className="flex justify-center my-10 text-center " style={{maxHeight: "60px"}}>
                    <div className='col-3'>
                            { (secondsConfig === 0 && minutesConfig === 0 ) ? ``
                     : (
                        <div className="container" style={{width: "33%"}}>
                        <img alt='fondo' src={fondoTwitch} style={{maxHeight: "62px" , width: "100%"}} />
                     
                        <div className='centered' > { minutes === 0 && seconds === 0
                        ? <h1 style={{fontSize: "40px"}}> 00:00 </h1>
                        : <h1 style={{fontSize: "40px"}}> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h1> 
                    }</div>
                    </div>
                    )}
                            
                            </div>       
                         
                            <div className='flex text-center border-x-4 border-t border-b bg-color px-5 py-3 jcc' style={{width: "50%"}}>
                            <div className='flex-1 text-left' style={{padding: 5, minWidth: "0"}}>
                            <span className="text-gray-300 mr-5 uppercase" style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block"}}>
                                {data.playerOne.name}
                            </span> 
                            </div>
                            <div className="flex-none">
                                <span className="font-semibold text-3xl">vs</span>
                            </div>
                            <div className='flex-1 text-right' style={{padding: 5, minWidth: "0"}}>
                                <span className="text-gray-300 uppercase" style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block"}}>
                                    {data.playerTwo.name}
                                </span>
                            </div>
                        </div>
                            <div className='col-3'></div>

                            
                </div>

                {/* Nuevo */}

                <div className='grid grid-cols-7 gap-2'>
                 
                    {data.steps.map((value: any, key: number) => (
                        <div key={key}>
                             
                            {key <= data.matchSplitMapOne && key <= (data.nextStepIndex) && (
                                <Veto secret={matchSecret} 
                                hash={String(hash)} 
                                currentName={currentPlayer} 
                                name={data.steps[key].player === 'playerOne' ? data.playerOne.name : data.playerTwo.name} 
                                type={data.steps[key].type} 
                                value={data.steps[key].value} 
                                maps={data.maps.items} 
                                mapsAvailable={data.maps.available} 
                                champions={data.champions.items}  
                                championsAvailable={data.champions.available} 
                                next={key >= data.nextStepIndex}
                                waitingon={next === 'playerOne' ? data.playerOne.name : data.playerTwo.name}/>
                            )}
                        </div>
                    )

                    )}           

                </div>

                {(data.matchSplitMapOne && data.matchSplitMapOne !== 999) && (
                        <div className="grid grid-cols-7 gap-2" style={{ marginTop: -20 }}>
                         {data.maps.items.map((value: any, key: number) => (
                        <div key={key}>
                           { (GetIndexMapPicked(key) >= 0) ? renderSplit(GetIndexMapPicked(key)) : renderNone()}
                        </div>
                     )

                         )}   
                        </div>
                    )}


                <audio id="NotificationSound" src="/assets/audio/ready.wav" crossOrigin="anonymous" preload="auto" />
            </div></>
            </div>
    ) : <LoadingSpinner />
}

export default Stream;